<template>
  <div class="shop_main">
    <div class="shop_search_menu">
      <a href="javascript:void(0)" @click="$router.replace('/index')">首页</a>
      <i class="el-icon-arrow-right"></i>
      <a href="javascript:void(0)">三方质检</a>
    </div>

    <img :src="'./img/banner_quality@3x.png'" class="page_banner" />

    <div class="shop_quality">

      <div class="shop_quality_title">
        <img class="title_img1" :src="'./img/qualityTesting_title1.png'" alt="">
      </div>

      <div class="shop_quality_item">
        <div class="item">
          <img class="item_bg" :src="'./img/qualityTesting_img01.png'" alt="">
          <img class="item_title" :src="'./img/qualityTesting_img04.png'" alt="">
          <p class="item_text">性状、鉴别、含量、农残、重金属等《中国药典》规定指标检测</p>
        </div>
        <div class="item">
          <img class="item_bg" :src="'./img/qualityTesting_img02.png'" alt="">
          <img class="item_title" :src="'./img/qualityTesting_img05.png'" alt="">
          <p class="item_text">农药残留、添加剂、微生物、重金属、真菌毒素、营养类别等检测</p>
        </div>
        <div class="item">
          <img class="item_bg" :src="'./img/qualityTesting_img03.png'" alt="">
          <img class="item_title" :src="'./img/qualityTesting_img06.png'" alt="">
          <p class="item_text">水分、农药残留、真菌毒素、营养类别等检测</p>
        </div>
      </div>

      <div class="shop_quality_title">
        <img class="title_img2" :src="'./img/qualityTesting_title2.png'" alt="">
      </div>

      <div class="shop_quality_example" :style="{'background-image': 'url(./img/qualityTesting_img07.png)'}">
        <div class="example borderBottom">
          <img class="mt11" :src="'./img/qualityTesting_img08.png'" alt="">
          <p>水分(二法)</p>
        </div>
        <div class="example borderBottom">
          <img class="mt11" :src="'./img/qualityTesting_img08.png'" alt="">
          <p>水分(四法)</p>
        </div>
        <div class="example borderBottom">
          <img class="mt11" :src="'./img/qualityTesting_img09.png'" alt="">
          <p>总灰分</p>
        </div>
        <div class="example borderBottom borderRight">
          <img class="mt11" :src="'./img/qualityTesting_img10.png'" alt="">
          <p>黄曲霉</p>
        </div>
        <div class="example">
          <img class="mt35" :src="'./img/qualityTesting_img11.png'" alt="">
          <p>浸出物</p>
        </div>
        <div class="example">
          <img class="mt35" :src="'./img/qualityTesting_img12.png'" alt="">
          <p>杂质</p>
        </div>
        <div class="example">
          <img class="mt35" :src="'./img/qualityTesting_img13.png'" alt="">
          <p>SO2</p>
        </div>
        <div class="example borderRight">
          <img class="mt35" :src="'./img/qualityTesting_img14.png'" alt="">
          <p>重金属</p>
        </div>
      </div>

      <div class="shop_quality_example_enterprise">
        <img :src="'./img/qualityTesting_img15.png'" alt="">
        <img :src="'./img/qualityTesting_img16.png'" alt="">
      </div>

      <div class="shop_quality_title">
        <img class="title_img3" :src="'./img/qualityTesting_title3.png'" alt="">
      </div>

      <div class="shop_quality_progress" :style="{'background-image': 'url(./img/qualityTesting_img17.png)'}">
        <img :src="'./img/qualityTesting_img25.png'" class="bgLine" />
        <div class="progress progress_bg1" :style="{'background-image': 'url(./img/qualityTesting_img18.png)'}">
          <div class="progress_con">
            <h3>沟通需求</h3>
            <p>填写检测委托单，业务员沟通确认质检信息。</p>
          </div>
        </div>
        <div class="progress progress_bg2" :style="{'background-image': 'url(./img/qualityTesting_img19.png)'}">
          <h3>签约缴费</h3>
          <p>签订合同，支付检测费用，提供多种付费方式。</p>
        </div>
        <div class="progress progress_bg3" :style="{'background-image': 'url(./img/qualityTesting_img20.png)'}">
          <h3>邮寄样品</h3>
          <p>平台用户邮寄样品到检测单位。</p>
        </div>
        <div class="progress progress_bg4" :style="{'background-image': 'url(./img/qualityTesting_img21.png)'}">
          <h3>样品分析</h3>
          <p>收到样品后，按用户需求提供检测。</p>
        </div>
        <div class="progress progress_bg5" :style="{'background-image': 'url(./img/qualityTesting_img22.png)'}">
          <h3>出具报告</h3>
          <p>电子版报告确认，纸质版报告邮寄。</p>
        </div>
      </div>

      <div class="shop_quality_title">
        <img class="title_img4" :src="'./img/qualityTesting_title4.png'" alt="">
      </div>

      <div class="shop_quality_enterprise">
        <img @click="$router.push('/shop/shopQualityTesting/guoLian')" :src="'./img/qualityTesting_img23hover.png'" alt="">
        <img @click="$router.push('/shop/shopQualityTesting/jianXun')" :src="'./img/qualityTesting_img24hover.png'" alt="">
      </div>

      <Contract :type="1"></Contract>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

const Contract = () => import('./contract');
export default {
  name: "index",
  computed: {
    ...mapState({
      listKeepAlive: state => state.app.listKeepAlive,
    })
  },
  activated () {
    if (this.listKeepAlive.isInitPageNo) {
      this.$store.commit('changeScrollTop');
    }
  },
  components: {
    Contract
  }
}
</script>

<style scoped>

</style>
